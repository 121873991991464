import React from "react"
import Layout from "../../components/layout/layout"
import SmallHero from "../../components/layout/smallHero"
import "../mystyles.scss"
import { Helmet } from "react-helmet"

import p1 from "../../img/joey_under_the_sun/profil20km.png"
import b1 from "../../img/joey_under_the_sun/p20km.png"
import p2 from "../../img/joey_under_the_sun/profil10km.png"
import b2 from "../../img/joey_under_the_sun/p10km.png"

export default function MaximalRace() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Joey under the sun</title>
      </Helmet>
      <SmallHero title="Joey under the sun" color="is-success" />
      <article className="section">
        <div className="container">
          <h1 className="title"> Joey under the sun </h1>
          <p>
            Le 13 mai est une date importante! Tout d'abord, c’est
            l'anniversaire de Stevie Wonder, donc tous nos vœux ! 71 ans, ça se
            fête ! Mais c'est surtout le jour de la grande course de notre club:
            "Joey under the sun". Deux formats sont possibles: Le semi-marathon
            et le 10km. La course se déroulera sur un terrain plat dans la
            région des trois lacs. Plusieurs ravitaillements seront à
            disposition des coureurs sur les différents parcours et une
            animation est prévue à l’arrivée. Il est évident que les mesures
            sanitaires seront respectées et que le comité d'organisation se
            donne le droit d'annuler l'événement jusqu'au mercredi 12 mai 2021.
            Vous trouverez notre concept de sécurité pour la course en bas de
            cette page.
          </p>
          <br />
          <h1 className="title">Programme</h1>
          <div className="content">
            <ul>
              <li>
                8h30: ouverture du stand pour récupérer les dossards et les prix
                souvenirs
              </li>
              <li>9h30: Départ du semi-marathon</li>
              <li>10h00: Départ du 10km </li>
              <li>12h15: Deadline pour finir les différentes courses </li>
              <li>12h30: Remise des prix </li>
            </ul>
          </div>
          <h1 className="title">Inscription</h1>
          <div className="content">
            <ul>
              <li>Inscription sans prix souvenirs: 15 francs</li>
              <li>
                Inscription avec prix souvenirs (Un T-shirt, une médaille, un
                sac surprise) : 40 francs
              </li>
            </ul>
            Pour vous inscrire, veuillez remplir le formulaire transmis sur "TK
            - communication". <br />
            Inscriptions possibles jusqu’au 11 avril 2021 !
          </div>
        </div>
        <br />
        <div className="container">
          <h1 className="title"> Parcours et dénivelé </h1>
          <br />
          <h2 className="subtitle"> 10km </h2>
          <div className="columns">
            <div className="column is-half">
              <h2 className="subtitle has-text-centered">Le parcours</h2>
              <figure className="image">
                <img src={b2} alt="parcours 5km" />
              </figure>
            </div>
            <div className="column is-half has-text-centered">
              <h2 className="subtitle">Le dénivelé</h2>
              <figure className="image">
                <img
                  src={p2}
                  alt="profil du parcours"
                  style={{
                    maxWidth: "75%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </figure>
            </div>
          </div>

          <h2 className="subtitle"> Semi-marathon </h2>
          <div className="columns">
            <div className="column is-half">
              <h2 className="subtitle has-text-centered">Le parcours</h2>
              <figure className="image">
                <img src={b1} alt="parcours 5km" />
              </figure>
            </div>
            <div className="column is-half has-text-centered">
              <h2 className="subtitle">Le dénivelé</h2>
              <figure className="image">
                <img
                  src={p1}
                  alt="profil du parcours"
                  style={{
                    maxWidth: "75%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </figure>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}
